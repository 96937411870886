import { useQuery } from '@apollo/client';
import moment from 'moment';
import { RenewalInfo } from './products';
import { useRenewalContext } from './RenewalContext';
import { GET_RENEWALS_AT } from './renewals.graphql';

export function useRenewals() {
  const { renewalDate } = useRenewalContext();
  return useRenewalsAt(renewalDate);
}

export function useNotifications() {
  const { notificationDate } = useRenewalContext();
  return useRenewalsAt(notificationDate);
}

export function useCurrents() {
  return useRenewalsAt(moment());
}

function useRenewalsAt(at: moment.Moment) {
  const { renewalDate } = useRenewalContext();

  const month = renewalDate.month() + 1;
  const year = renewalDate.year();

  const { loading, data } = useQuery<{ renewals: RenewalInfo[] }>(GET_RENEWALS_AT, {
    variables: { month, year, at: at.format('YYYY-MM-DD') },
  });

  return {
    renewals: data?.renewals,
    loading,
  };
}
