import { TableHead, TableCell, Table, TableRow, Typography, Stack } from '@mui/material';
import Fuse from 'fuse.js';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Counter } from '../../components/Counter';
import LoadingSpinner from '../../components/LoadingSpinner';
import { SearchField } from '../../components/SearchField';
import { numberAsKr } from '../../Formatters';
import { RenewalInfo } from '../products';
import { useRenewalContext } from '../RenewalContext';
import InfiniteScroll from 'react-infinite-scroller';
import { useCurrents } from '../useData';
import moment from 'moment';
import { DiffMaconomyApi } from '../Diff/DiffMaconomyApiDialog';
import { DiffMaconomyFileDialog } from '../Diff/DiffMaconomyFileDialog';
import { ExcelExport } from './ExcelExport';
import { products } from '../products';

export const RenewalTable: FC = () => {
  const [numberOfItems, setNumberOfItems] = useState(50);
  const [searchTerm, setSearchTerm] = useState('');
  const { renewalDate } = useRenewalContext();
  const { renewals, loading } = useCurrents();

  const renewalsCount = renewals ? renewals.length : 0;
  const filteredRenewals = renewals ? filterRenewals(renewals, searchTerm) : [];
  const notRenewedYet = renewalDate > moment();

  return (
    <div>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6">Kunder i fornyelse</Typography>
        <Stack direction="row">
          {notRenewedYet && <DiffMaconomyApi />}
          <DiffMaconomyFileDialog />
          {renewals && <ExcelExport current={renewals} name="Kunder i fornyelse" />}
        </Stack>
      </Stack>
      <SearchField filter={setSearchTerm} />
      <Counter list={filteredRenewals} />
      {loading && <LoadingSpinner />}
      {renewals && (
        <Table sx={{ mt: 1 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Kunde</TableCell>
              <TableCell>Abonnement</TableCell>
              {products.map((p) => (
                <TableCell key={`HEADER-${p.name}`} align="right">
                  {p.name}
                </TableCell>
              ))}
              <TableCell align="right">Sum</TableCell>
            </TableRow>
          </TableHead>
          <InfiniteScroll element="tbody" loadMore={() => setNumberOfItems(numberOfItems + 500)} hasMore={renewalsCount > numberOfItems}>
            {filteredRenewals.slice(0, numberOfItems).map((r) => (
              <RenewalRow key={r.id} r={r} />
            ))}
          </InfiniteScroll>
        </Table>
      )}
    </div>
  );
};

function filterRenewals(renewals: RenewalInfo[], searchTerm: string) {
  var options = {
    keys: ['name', 'subscriptionOrderNumber'],
  };
  if (!searchTerm) {
    return renewals;
  } else {
    var fuse = new Fuse(renewals, options);
    var filtered = fuse.search(searchTerm);
    return filtered;
  }
}

const RenewalRow: FC<{ r: RenewalInfo }> = ({ r }) => {
  return (
    <TableRow key={r.licenseId}>
      <TableCell>
        <Link to={'/license/' + r.licenseId}>{r.name}</Link>
      </TableCell>
      <TableCell>{r.subscriptionOrderNumber}</TableCell>
      {products.map((p) => (
        <TableCell key={`${r.id}${p.name}`} align="right">
          {numberAsKr(p.selector(r))}
        </TableCell>
      ))}
      <TableCell align="right">{numberAsKr(r.total)}</TableCell>
    </TableRow>
  );
};
