import { FC } from 'react';
import { useRenewalContext } from '../RenewalContext';
import { useCurrents, useNotifications } from '../useData';
import { Diff } from './DiffDialog';

export const DiffNotification: FC = () => {
  const { notificationDate } = useRenewalContext();
  const title = `Endringer fra da det ble sendt varsel om fornyelse (${notificationDate.format('D MMMM YYYY')})`;

  return (
    <Diff
      title={title}
      useBaseline={useNotifications}
      useCurrent={useCurrents}
      baselineName="Ved varsel"
      currentName="Nå"
      shortName="Endringer siden varsel"
    />
  );
};
