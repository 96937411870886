import { Typography } from '@mui/material';
import { FC } from 'react';
import { numberAsKr } from '../../Formatters';

export const SummaryRow: FC<{
  value: number;
  formatter?: (val: number | undefined) => string | undefined;
  colorValue?: boolean;
}> = ({ value, formatter, colorValue }) => {
  const valueFormatter = formatter ?? ((val: number | undefined) => numberAsKr(val));
  return (
    <Typography
      textAlign="right"
      fontSize="small"
      color={colorValue ? (theme) => (value > 0 ? theme.palette.error.light : theme.palette.info.dark) : undefined}>
      {valueFormatter(value) ?? '-'}
    </Typography>
  );
};
