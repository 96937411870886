import { Typography, Stack, Divider } from '@mui/material';
import { FC } from 'react';

export const SummaryHeadingColumn: FC<{ topGutter?: boolean }> = ({ topGutter }) => {
  return (
    <Stack mt={topGutter ? 5.5 : undefined}>
      <Typography fontSize="small">Antall kunder</Typography>
      <Typography fontSize="small">BFS</Typography>
      <Stack ml={1}>
        <Typography fontSize="small">Komplett</Typography>
        <Stack ml={1}>
          <Typography fontSize="small">Planlegging</Typography>
          <Typography fontSize="small">Byggdetaljer</Typography>
          <Typography fontSize="small">Byggforvaltning</Typography>
          <Typography fontSize="small">Utførelse</Typography>
        </Stack>
      </Stack>
      <Typography fontSize="small">Bvn</Typography>
      <Divider />
      <Typography fontSize="small" fontWeight="medium">
        Totalt
      </Typography>
    </Stack>
  );
};
