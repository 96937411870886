import { ArrowLeft, ArrowRight, FastForward, FastRewind } from '@mui/icons-material';
import { Typography, Stack, IconButton, debounce } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import moment, { Moment } from 'moment';
import { useRenewalContext } from './RenewalContext';

export const RenewalNavigation: FC = () => {
  const { renewalDate: date, setDate } = useRenewalContext();
  const [workingDate, setWorkingDate] = useState(date.clone());
  const delayedChangeMonth = useCallback(
    (m: Moment) => {
      const debouncedFn = debounce((value: Moment) => setDate(value), 250);
      debouncedFn(m);
    },
    [setDate],
  );

  const updateDate = (updater: (dt: Moment) => Moment) => {
    const newDate = updater(workingDate.clone());
    setWorkingDate(newDate);
    delayedChangeMonth(newDate);
  };

  const nextPeriod = () => updateDate((dt) => dt.add(1, 'months'));
  const nextYear = () => updateDate((dt) => dt.add(1, 'years'));
  const prevPeriod = () => updateDate((dt) => dt.subtract(1, 'months'));
  const prevYear = () => updateDate((dt) => dt.subtract(1, 'years'));

  const startOfEvents = moment([2020, 4, 1]);
  const canGoBackSinglePeriod = date > startOfEvents;
  const isInSync = date.format() === workingDate.format();
  const canGoBackYear = workingDate.clone().subtract(1, 'years') > startOfEvents;

  return (
    <Stack direction="row" alignItems="center">
      <IconButton title="Forrige år" onClick={prevYear} size="large" color="primary" disabled={!canGoBackYear}>
        <FastRewind />
      </IconButton>
      <IconButton title="Forrige måned" onClick={prevPeriod} size="large" color="primary" disabled={!canGoBackSinglePeriod}>
        <ArrowLeft fontSize="large" />
      </IconButton>
      <Typography variant="h5" component="div" sx={{ minWidth: 310, color: isInSync ? undefined : (theme) => theme.palette.secondary.light }}>
        Fornyelser i {workingDate.format('MMMM YYYY')}
      </Typography>
      <IconButton title="Neste måned" onClick={nextPeriod} size="large" color="primary">
        <ArrowRight fontSize="large" />
      </IconButton>
      <IconButton title="Neste år" onClick={nextYear} size="large" color="primary">
        <FastForward />
      </IconButton>
    </Stack>
  );
};
