import { FC } from 'react';
import { useRenewalContext } from '../RenewalContext';
import { useCurrents, useRenewals } from '../useData';
import { Diff } from './DiffDialog';

export const DiffRenewal: FC = () => {
  const { renewalDate } = useRenewalContext();
  const title = `Endringer fra fornyelse (${renewalDate.format('D MMMM YYYY')})`;

  return (
    <Diff
      title={title}
      shortName="Endringer siden fornyelse"
      useBaseline={useRenewals}
      useCurrent={useCurrents}
      baselineName="Ved fornyelse"
      currentName="Nå"
    />
  );
};
