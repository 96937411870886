import { FC, useState } from 'react';
import { IconButton, CircularProgress } from '@mui/material';
import { RenewalInfo } from '../products';
import { useQuery } from '@apollo/client';
import { GET_RENEWALS_MACONOMY } from '../renewals.graphql';
import { Difference, Error } from '@mui/icons-material';
import { useRenewalContext } from '../RenewalContext';
import { useRenewals } from '../useData';
import { DiffMaconomyDialog } from './DiffMaconomyDialog';

export const DiffMaconomyApi: FC = () => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      {open && <DiffMaconomyApiDialog onClose={() => setOpen(false)} />}
      <IconButton color="secondary" onClick={(_) => setOpen(true)} title="Sammenlign med Maconomy" size="small">
        <Difference fontSize="small" />
      </IconButton>
    </div>
  );
};

const DiffMaconomyApiDialog: FC<{ onClose(): void }> = ({ onClose }) => {
  const { renewalDate: date } = useRenewalContext();
  const { renewals } = useRenewals();
  const month = date.month() + 1;
  const year = date.year();

  const { loading, error, data } = useQuery<{ renewals: RenewalInfo[] }>(GET_RENEWALS_MACONOMY, { variables: { month, year } });
  if (loading) return <CircularProgress size={15} />;
  if (error) return <Error color="error" fontSize="small" titleAccess={error.message} />;
  if (!data || !renewals) return null;

  return <DiffMaconomyDialog onClose={onClose} ours={renewals} theirs={data.renewals} />;
};
