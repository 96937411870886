import React, { FC, useState } from 'react';
import moment, { Moment } from 'moment';

interface RenewalContext {
  renewalDate: Moment;
  hasRenewal: boolean;
  notificationDate: Moment;
  hasNotification: boolean;
  setDate: (date: Moment) => void;
}

const RenewalContextInstance = React.createContext<RenewalContext | undefined>(undefined);

export const useRenewalContext = (): RenewalContext => {
  const context = React.useContext(RenewalContextInstance);
  if (!context) {
    throw new Error('useRenewalContext must be used within a RenewalContextProvider');
  }
  return context;
};

export const RenewalContextProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const now = moment();
  const nextMonth = now.clone().startOf('month').add(1, 'months');
  const currentPeriod = nextMonth;

  const [date, setDate] = useState(currentPeriod);
  const workingPeriod = date;

  const month = workingPeriod.month() + 1;
  const notificationDate = workingPeriod
    .clone()
    .subtract(1, 'months')
    .subtract(month === 1 ? 16 : 0, 'days');

  const contextValue: RenewalContext = {
    renewalDate: workingPeriod.clone(),
    hasRenewal: workingPeriod < now,
    setDate: (date: Moment) => setDate(date.clone()),
    notificationDate: notificationDate.clone(),
    hasNotification: notificationDate < now,
  };

  return <RenewalContextInstance.Provider value={contextValue}>{children}</RenewalContextInstance.Provider>;
};
