import { FC, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { RenewalInfo } from '../products';
import Dropzone from 'react-dropzone';
import XLSX from 'xlsx';
import { UploadFile } from '@mui/icons-material';
import { useRenewals } from '../useData';
import { DiffMaconomyDialog } from './DiffMaconomyDialog';

const useStyles = makeStyles((theme) => ({
  ours: {
    color: theme.palette.primary.main,
  },
  theirs: {
    color: theme.palette.error.main,
  },
  dropzoneBox: {
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    padding: 10,
  },
  dropZoneContainer: {},
  spinnerContainer: {
    marginLeft: 100,
    marginTop: 5,
  },
}));

export const DiffMaconomyFileDialog: FC = () => {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <IconButton size="small" title="Sammenlign med Excel-fil" onClick={() => setOpen(true)}>
        <UploadFile fontSize="small" />
      </IconButton>
      {open && <DiffMaconomyDialog2 onClose={() => setOpen(false)} />}
    </div>
  );
};

interface MaconomyItem {
  subscriptionNumber: string;
  customerNumber: string;
  subscription: string;
  sum: number;
}

const DiffMaconomyDialog2: FC<{ onClose(): void }> = ({ onClose }) => {
  const { renewals } = useRenewals();
  const [items, setItems] = useState<RenewalInfo[]>();

  if (items) return <DiffMaconomyDialog ours={renewals!} theirs={items} onClose={onClose} />;

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>{items ? 'Sammenlign' : 'Last opp Excel fil'}</DialogTitle>
      <DialogContent>{!items && <FileUploader onUploaded={(items) => setItems(mapFromMaconomyItems(items))} />}</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Lukk</Button>
      </DialogActions>
    </Dialog>
  );
};

function mapFromMaconomyItems(items: MaconomyItem[]): RenewalInfo[] | undefined {
  const grouped = items.reduce(
    (acc, item) => {
      if (!acc[item.subscriptionNumber]) {
        acc[item.subscriptionNumber] = [];
      }
      acc[item.subscriptionNumber].push(item);
      return acc;
    },
    {} as Record<string, MaconomyItem[]>,
  );
  return Object.values(grouped).map((items) => {
    const getValue = (subscription: string) => items.filter((x) => x.subscription === subscription).reduce((acc, item) => acc + item.sum, 0);
    return {
      id: items[0].subscriptionNumber,
      licenseId: 0,
      subscriptionOrderNumber: items[0].subscriptionNumber,
      name: 'Ukjent',
      bfs: getValue('BFS'),
      bfsp: getValue('BFSP'),
      bfsb: getValue('BFSB'),
      bfsf: getValue('BFSF'),
      bfsu: getValue('BFSU'),
      bvn: getValue('BVN'),
      total: items.reduce((acc, item) => acc + item.sum, 0),
    };
  });
}

function FileUploader({ onUploaded }: { onUploaded(items: MaconomyItem[]): void }) {
  const classes = useStyles({});

  const addFile = async (newFiles: File[]) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      if (e.target && e.target.result) {
        const data = new Uint8Array(e.target.result as ArrayBufferLike);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheet = workbook.Sheets['Abonnement_fakturering_sjekker_'];
        const array: MaconomyItem[] = [];
        var currentRow = 13;
        while (sheet.hasOwnProperty(`A${currentRow}`)) {
          array.push({
            subscriptionNumber: sheet[`A${currentRow}`].v.toString(),
            customerNumber: sheet[`B${currentRow}`].v,
            subscription: sheet[`F${currentRow}`].v,
            sum: sheet[`K${currentRow}`].v,
          });
          currentRow++;
        }
        onUploaded(array);
      }
    };
    reader.readAsArrayBuffer(newFiles[0]);
  };

  return (
    <div className={classes.dropZoneContainer}>
      <div>
        <Dropzone onDrop={addFile} accept=".xlsx">
          {({ getRootProps, getInputProps }) => (
            <section>
              <div className={classes.dropzoneBox} {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Dra og slipp Maconomy rapport her for å sammenligne, eller klikk for å velge</p>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    </div>
  );
}
