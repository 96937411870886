export const products = [
  { name: 'Komplett', selector: (x: RenewalInfo) => x.bfs },
  { name: 'Planlegging', selector: (x: RenewalInfo) => x.bfsp },
  { name: 'Byggdetaljer', selector: (x: RenewalInfo) => x.bfsb },
  { name: 'Byggforvaltning', selector: (x: RenewalInfo) => x.bfsf },
  { name: 'Utførelse', selector: (x: RenewalInfo) => x.bfsu },
  { name: 'BVN', selector: (x: RenewalInfo) => x.bvn },
];

export interface RenewalInfo {
  id: string;
  licenseId: number;
  subscriptionOrderNumber: string;
  name: string;
  bfs?: number;
  bfsp?: number;
  bfsb?: number;
  bfsf?: number;
  bfsu?: number;
  bvn?: number;
  total: number;
}
