import { FC } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableBody,
  Stack,
  Typography,
  Divider,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import { RenewalInfo, products } from '../products';
import { numberAsKr } from '../../Formatters';
import moment from 'moment';
import { useRenewalContext } from '../RenewalContext';
import { ExcelExport } from './ExcelExport';

const useStyles = makeStyles((theme) => ({
  ours: {
    color: theme.palette.info.dark,
  },
  theirs: {
    color: theme.palette.error.main,
  },
}));

export const DiffMaconomyDialog: FC<{ onClose(): void; ours: RenewalInfo[]; theirs: RenewalInfo[] }> = ({ onClose, ours, theirs }) => {
  const { renewalDate } = useRenewalContext();
  const classes = useStyles();

  const missing = theirs.filter((x) => ours.find((r) => r.subscriptionOrderNumber === x.subscriptionOrderNumber) === undefined);
  const array = ours.concat(
    missing.map((item, index) => ({
      subscriptionOrderNumber: item.subscriptionOrderNumber,
      licenseId: 0,
      total: 0,
      id: 'id-' + index,
      name: 'Ukjent',
    })),
  );

  const diffs = array.filter((x) => {
    const renewal = theirs.find((r) => r.subscriptionOrderNumber === x.subscriptionOrderNumber);
    return renewal === undefined || x.total !== renewal.total;
  });

  const getTheirValueOrUndefined = (subscriptionOrderNumber: string, selector: (x: RenewalInfo) => number | undefined) => {
    const item = theirs.find((x) => x.subscriptionOrderNumber === subscriptionOrderNumber);
    if (!item) return undefined;
    return selector(item);
  };

  return (
    <Dialog open onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle>Avvik i Maconomy ({moment(renewalDate).format('MMMM YYYY')})</DialogTitle>
      <DialogContent>
        <Stack direction="row" spacing={1}>
          <Typography className={classes.ours}>SalgAdmin</Typography>
          <Divider orientation="vertical" flexItem />
          <Typography className={classes.theirs}>Maconomy</Typography>
        </Stack>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>Kunde</TableCell>
              <TableCell>Abonnement</TableCell>
              {products.map((p) => (
                <TableCell key={`HEADER-${p.name}`} align="right">
                  {p.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {diffs.map((item) => {
              return (
                <TableRow key={item.id}>
                  <TableCell>
                    {item.licenseId && item.licenseId !== 0 ? <Link to={'/license/' + item.licenseId}>{item.name}</Link> : item.name}
                  </TableCell>
                  <TableCell>{item.subscriptionOrderNumber}</TableCell>
                  {products.map((p) => (
                    <DiffCell
                      key={`cell-${item.id}-${p.name}`}
                      a={p.selector(item)}
                      b={getTheirValueOrUndefined(item.subscriptionOrderNumber, p.selector)}
                    />
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <ExcelExport
            baseline={array}
            current={theirs}
            baselineName="SalgAdmin"
            currentName="Maconomy"
            name="Avvik i Maconomy"
            predicate={(a, b) => a.subscriptionOrderNumber === b.subscriptionOrderNumber}
          />
          <Button variant="contained" onClick={onClose}>
            Lukk
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

const DiffCell: FC<{ a: number | undefined; b: number | undefined }> = ({ a, b }) => {
  const classes = useStyles();

  if (!hasDiff(a, b)) return <TableCell />;
  return (
    <TableCell align="right">
      <Stack direction="row" justifyContent="right">
        <span className={classes.ours}>{numberAsKr(a)}</span>
        <span className={classes.theirs}>{numberAsKr(b)}</span>
      </Stack>
    </TableCell>
  );
};

function hasDiff(a: number | undefined, b: number | undefined): boolean {
  if (!a === undefined && !b === undefined) return false;
  if (a === undefined || b === undefined) return true;

  return roundToTwo(a) !== roundToTwo(b);
}

function roundToTwo(num: number) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}
