import { Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { FC } from 'react';
import { DiffNotification } from '../Diff/DiffNotificationDialog';
import { DiffRenewal } from '../Diff/DiffRenewalDialog';
import { useRenewalContext } from '../RenewalContext';
import { Summary } from './Summary';
import {
  useNotificationDiffSummary,
  useNotificationDiffPercentageSummary,
  useRenewalDiffSummary,
  useNotificationSummary,
  useRenewalSummary,
  useCurrentSummary,
} from './summaryUtils';
import { SummaryHeadingColumn } from './SummaryHeadingColumn';

export const SummaryOverview: FC = () => {
  const { hasNotification, hasRenewal } = useRenewalContext();
  return (
    <div>
      <Typography variant="h6">Oppsummering</Typography>
      <Grid2 container gap={4}>
        <SummaryHeadingColumn topGutter />
        {hasNotification && <NotificationSummary />}
        {hasRenewal && <RenewalSummary />}
        <CurrentSummary />
        {hasRenewal && <RenewalDiffSummary />}
        {hasNotification && <NotificationDiffSummary />}
        {hasNotification && <NotificationDiffPercentageSummary />}
      </Grid2>
    </div>
  );
};

const NotificationDiffSummary: FC = () => {
  const { summary } = useNotificationDiffSummary();
  return <Summary summary={summary} label="Endring siden varsel" colorValues actions={<DiffNotification />} />;
};

const NotificationDiffPercentageSummary: FC = () => {
  const { summary } = useNotificationDiffPercentageSummary();
  const formatter = (val: number | undefined) => {
    if (!val) return undefined;
    return val.toLocaleString('nb-NO', { style: 'percent', maximumFractionDigits: 1 });
  };
  return <Summary summary={summary} label="Totalt frafall %" colorValues formatter={formatter} />;
};

const RenewalDiffSummary: FC = () => {
  const { summary } = useRenewalDiffSummary();
  return <Summary summary={summary} label="Endring siden fornyelse" colorValues actions={<DiffRenewal />} />;
};

const NotificationSummary: FC = () => {
  const { notificationDate } = useRenewalContext();
  const { summary } = useNotificationSummary();
  return <Summary summary={summary} label={`Ved varsel (${notificationDate.format('D.M.YY')})`} />;
};

const RenewalSummary: FC = () => {
  const { renewalDate } = useRenewalContext();
  const { summary } = useRenewalSummary();

  return <Summary summary={summary} label={`Ved fornyelse (${renewalDate.format('D.M.YY')})`} />;
};

const CurrentSummary: FC = () => {
  const { summary } = useCurrentSummary();
  return <Summary summary={summary} label="Nå" />;
};
