import { FC, useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableBody,
  IconButton,
  Stack,
  Typography,
  Divider,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { RenewalInfo, products } from '../products';
import { Search } from '@mui/icons-material';
import LoadingSpinner from '../../components/LoadingSpinner';
import { ExcelExport } from './ExcelExport';

export const Diff: FC<{
  title: string;
  shortName: string;
  useBaseline: () => { renewals: RenewalInfo[] | undefined };
  useCurrent: () => { renewals: RenewalInfo[] | undefined };
  baselineName: string;
  currentName: string;
}> = ({ title, shortName, baselineName, currentName, useBaseline, useCurrent }) => {
  const [open, setOpen] = useState(false);

  return (
    <div style={{ textAlign: 'right' }}>
      <IconButton color="secondary" size="small" onClick={(_) => setOpen(true)} title={`Se ${title.toLowerCase()}`}>
        <Search fontSize="small" />
      </IconButton>
      {open && (
        <DiffDialog
          title={title}
          shortName={shortName}
          onClose={() => setOpen(false)}
          useBaseline={useBaseline}
          useCurrent={useCurrent}
          baselineName={baselineName}
          currentName={currentName}
        />
      )}
    </div>
  );
};

export const DiffDialog: FC<{
  onClose(): void;
  title: string;
  shortName: string;
  useBaseline: () => { renewals: RenewalInfo[] | undefined };
  useCurrent: () => { renewals: RenewalInfo[] | undefined };
  baselineName: string;
  currentName: string;
}> = ({ onClose, title, useBaseline, useCurrent, baselineName, currentName, shortName }) => {
  const { renewals: notifications } = useBaseline();
  const { renewals } = useCurrent();

  const diffs = notifications?.filter((x) => {
    const renewal = renewals?.find((r) => r.licenseId === x.licenseId);
    return renewal === undefined || x.total !== renewal.total;
  });

  return (
    <Dialog open onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack direction="row" spacing={1}>
          <Typography sx={{ color: (theme) => theme.palette.error.light }}>{baselineName}</Typography>
          <Divider orientation="vertical" flexItem />
          <Typography sx={{ color: (theme) => theme.palette.info.dark }}>{currentName}</Typography>
        </Stack>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Kunde</TableCell>
              <TableCell>Abonnement</TableCell>
              {products.map((p) => (
                <TableCell key={`HEADER-${p.name}`} align="right">
                  {p.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!diffs && (
              <TableRow>
                <TableCell colSpan={8}>
                  <LoadingSpinner />
                </TableCell>
              </TableRow>
            )}
            {diffs &&
              diffs.map((notification) => {
                const renewal = renewals?.find((x) => x.licenseId === notification.licenseId);
                const isRemoved = renewal === undefined;
                return (
                  <TableRow key={notification.licenseId}>
                    <TableCell>
                      <Link to={'/license/' + notification.licenseId}>{notification.name}</Link>
                    </TableCell>
                    <TableCell>{notification!.subscriptionOrderNumber}</TableCell>
                    {products.map((p) => (
                      <TableCell key={`${notification.id}${p.name}`} align="right">
                        <DiffDisplayer a={p.selector(notification)} b={isRemoved ? undefined : p.selector(renewal!)} />
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          {notifications && renewals && (
            <ExcelExport baselineName={baselineName} currentName={currentName} baseline={notifications} current={renewals} name={shortName} />
          )}
          <Button onClick={onClose} variant="contained">
            Lukk
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

function DiffDisplayer({ a, b }: { a: number | undefined; b: number | undefined }) {
  if (a === undefined && b === undefined) return null;

  const numberA = a ?? 0;
  const numberB = b ?? 0;
  if (numberA === numberB) return null;

  return (
    <Stack direction="row" justifyContent="right" spacing={0.5}>
      <Typography sx={{ fontSize: 'inherit', textDecoration: 'line-through', color: (theme) => theme.palette.error.light }}>
        {toKr(numberA)}
      </Typography>
      <Divider orientation="vertical" flexItem />
      <Typography sx={{ fontSize: 'inherit', color: (theme) => theme.palette.info.dark }}>{toKr(numberB)}</Typography>
    </Stack>
  );
}

function toKr(val: number): string {
  return val.toLocaleString('nb-NO', { style: 'currency', currency: 'NOK', maximumFractionDigits: 2 });
}
