import { Stack, Divider, Typography, Skeleton, Box } from '@mui/material';
import { FC } from 'react';
import { numberFormatted } from '../../Formatters';
import { SummaryType } from './summaryUtils';
import { SummaryRow } from './SummaryRow';

export const Summary: FC<{
  summary: SummaryType;
  label?: React.ReactNode;
  actions?: React.ReactNode;
  colorValues?: boolean;
  formatter?: (val: number | undefined) => string | undefined;
}> = ({ summary, label, actions, colorValues, formatter }) => {
  if (summary === null) return null;
  return (
    <Stack>
      {label && (
        <Stack justifyContent="right">
          <Box sx={{ height: (theme) => theme.spacing(3) }}>{actions}</Box>
          {typeof label === 'string' ? (
            <Typography fontSize="small" fontWeight="medium" textAlign="right">
              {label}
            </Typography>
          ) : (
            label
          )}
        </Stack>
      )}
      {summary === undefined && <Skeleton variant="rectangular" height={180} width="100%" sx={{ minWidth: 100 }} />}
      {summary && (
        <>
          <SummaryRow value={summary.numberOfCustomers} formatter={formatter ?? numberFormatted} colorValue={colorValues} />
          <SummaryRow value={summary.bfs.total} colorValue={colorValues} formatter={formatter} />
          <Stack ml={1}>
            <SummaryRow value={summary.bfs.komplett.total} colorValue={colorValues} formatter={formatter} />
            <Stack ml={1}>
              <SummaryRow value={summary.bfs.komplett.planlegging} colorValue={colorValues} formatter={formatter} />
              <SummaryRow value={summary.bfs.komplett.byggdetaljer} colorValue={colorValues} formatter={formatter} />
              <SummaryRow value={summary.bfs.komplett.byggforvaltning} colorValue={colorValues} formatter={formatter} />
              <SummaryRow value={summary.bfs.komplett.utførelse} colorValue={colorValues} formatter={formatter} />
            </Stack>
          </Stack>
          <SummaryRow value={summary.bvn} colorValue={colorValues} formatter={formatter} />
          <Divider />
          <SummaryRow value={summary.total} colorValue={colorValues} formatter={formatter} />
        </>
      )}
    </Stack>
  );
};
